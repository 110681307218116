//imports
@use "../../styles/global/breakpoints" as b;
@import "../../styles/global/variables";

.sidebar {
  display: none;
  position: relative;
  background-color: $white;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: $second;

  @include b.breakpoint("1280") {
    display: block;
  }

  .heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;

    h1 {
      font-size: 40px;
    }
    img {
      width: 70% !important;
      height: auto;
      padding-left: 1.5rem;
    }
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 1.5rem;
      cursor: pointer;

      gap: 0.7rem;

      p {
        font-size: 17px;
        font-weight: 400;
        line-height: 1.8;
        color: #2a303c;
      }

      &:hover {
        background-color: #d6d6d736;
        border-left: 5px solid #18181850;
        padding: 1rem calc(1.5rem - 5px);
      }

      &.active-menu {
        background-color: #d6d6d736;
        border-left: 5px solid $accent;
      }
    }
    li.active-menu {
      padding: 1rem calc(1.5rem - 5px);
    }

    .icon-wrap {
      width: 30px;
      height: 30px;
      background-color: white;
      box-shadow: 0 0 10px 5px rgba(16, 16, 29, 0.122);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      opacity: 0.7;
    }
    svg {
      color: $dash;
      font-size: 15px;
    }
  }
}

.seperator-element {
  height: 0.5px;
  margin: 2rem 1.5rem;
  background-color: $dash;
  opacity: 0.4;
}

.logout {
  position: absolute;
  bottom: 1.5rem;
  width: 82%;
  margin: 0 1.5rem;

  li {
    border: 1px solid $dash;
    border-radius: 15px;
    margin: 0 auto;
    padding: 0.7rem 1rem !important;
  }
  li:hover {
    background-color: transparent !important;
  }

  p {
    text-transform: capitalize !important;
  }
}
