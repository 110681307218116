$breakpoint-n: (
  "500": 500px,
  "768": 768px,
  "800": 800px,
  "900": 900px,
  "1000": 1000px,
  "1280": 1280px,
  "1300": 1300px,
  "1440": 1440px,
  "1680": 1680px,
  "2640": 2640px,
);

@mixin breakpoint($size) {
  @media (min-width: map-get($breakpoint-n, $size)) {
    @content;
  }
}
