//imports
@use "../../styles/global/breakpoints" as b;
@import "../../styles/global/variables";

.settings {
  margin-bottom: 7rem;
}

.settings-domains {
  display: block;

  @include b.breakpoint("768") {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .add-new {
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
    background-color: $white;
    border-radius: 10px;
    padding: 2rem;
    height: fit-content;

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .domains-list {
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    border-radius: 10px;
    padding: 0;
    padding-top: 1rem;

    @include b.breakpoint("768") {
      padding: 2rem;
    }

    h1 {
      font-size: 20px;
    }
    & > p {
      font-size: 14px;
      color: $second;
      padding-top: 0.3rem;
    }
  }
}

.table-container {
  margin-bottom: 0 !important;
}
