@use "../../styles/global/breakpoints" as b;
@import "../../styles/global/variables";

.table-container {
  margin-top: 2.5rem;
  margin-bottom: 5.5rem;
}

table {
  input {
    border: 1px solid $second !important;
  }
  thead {
    th {
      background-color: $white !important;
      border-bottom: 1px solid #8b8b8b35;
    }
  }
  tbody {
    tr,
    td,
    th {
      background-color: #f9fafb !important;
    }

    tr {
      border-bottom: 1px solid #8b8b8b35;
      &:last-child {
        border: none;
      }
    }
    tr .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: 27px;
      svg {
        cursor: pointer;
      }
    }
  }
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 1rem;

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;

    button,
    label {
      text-transform: capitalize;

      font-size: 17px;
      display: flex;
      flex-direction: row;
      gap: 5px;

      p {
        font-size: 15px;
      }
    }
    .changed {
      background-color: $white;
      border: 5px solid white;
      color: $dash;
    }
    p {
      font-size: 15px;
    }
  }

  .search {
    form {
      display: flex;
      flex-direction: row;
      input {
        border-radius: 10px 0 0 10px;
      }
      button {
        font-size: 20px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
}

.table-footer {
  width: 100%;
  background-color: $white;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 1rem;
  border-top: 1px solid #8b8b8b35;

  .elms {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 12px !important;
      @include b.breakpoint("768") {
        font-size: 16px !important;
      }
    }

    button {
      background-color: $white;
      border: 3px solid #8b8b8b35;
      color: $dash;
      text-transform: capitalize;
    }
  }
}

// search bar on restricted pages
.expand-search {
  grid-template-columns: 1fr !important;
  gap: 1rem;

  @include b.breakpoint("768") {
    grid-template-columns: 1fr 0.6fr !important;
  }

  .actions {
    // padding-left: 1rem;

    svg {
      pointer-events: none;
      font-size: 20px;
    }
  }
}

.small-remove {
  text-transform: capitalize !important;
}

.popup-form {
  margin-top: 1.5rem;

  .inputs {
    margin-bottom: 0.8rem;
    margin-top: 0.3rem;
  }
}

tbody td span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #97979b;
}

tr {
  display: flex;
  flex-direction: column;

  @include b.breakpoint("768") {
    flex-direction: row;
    justify-content: space-between;
  }
}
tr td {
  font-size: 15px !important;

  @include b.breakpoint("768") {
    font-size: 16px !important;
  }
}
tr td button.btn {
  font-size: 12px !important;

  @include b.breakpoint("768") {
    font-size: 15px !important;
  }
}
