//imports
@import "../../styles/global/variables";

.notfound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1.5rem;
  border-radius: 10px;
  gap: 0.7rem;

  h1 {
    font-size: 60px;
    line-height: 1;
  }
}
