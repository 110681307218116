@import "../../styles/global/variables";

// back element of the sidemenu (helps for easy closing of the menu)
.back {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #c0c0c086;
  z-index: 8;
}

// side menu
.side-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70%;
  background-color: $white;
  z-index: 9;

  color: $dash;
  font-size: 18px;
  font-weight: 500;
  padding: 2rem;
  box-shadow: 0 0 10px 10px rgba(109, 109, 109, 0.096);
  border-radius: 10px 0 0 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  a {
    width: 100%;

    li {
      font-size: 20px;
    }

    .mobile-account {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $accent;

      color: white;
      padding: 0.5rem;
      gap: 0.5rem;
      border: 2px solid white;
    }
  }
}

// closing icon
.close {
  position: fixed;
  top: 2rem;
  left: 1rem;
  z-index: 10;
  color: #0c003d;
  font-size: 30px;
  border: 3px solid $dash;
  border-radius: 20px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
