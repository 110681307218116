@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

@layer base {
  html {
    font-family: Poppins, system-ui, sans-serif;
  }
}
