//imports
@use "../global/breakpoints" as b;
@import "./variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  max-width: 100vw;
  min-height: 100vh;
  background-color: $background !important;
  overflow-x: hidden; /* fallback to clip */
  overflow-x: clip;
  scroll-behavior: smooth;
  position: relative;
}

#root {
  height: 100vh;
  width: 100vw;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 5px;

  background: white;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1013201d;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #2a303c;
  border-radius: 10px;
}

.site-container {
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;

  @include b.breakpoint("1280") {
    grid-template-columns: 18rem 1fr;
  }

  .main {
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    overflow-y: auto;
  }
}

.centerer {
  width: 90%;
  margin: 0 auto;
  margin-top: calc(-120px + 2rem);
  margin-bottom: 5rem;
  background-color: $white;
  padding: 1rem 1rem 3rem 1rem;
  border-radius: 10px;
  min-height: 50vh;

  @include b.breakpoint("768") {
    padding: 2rem 2rem 5rem 2rem;
  }

  @include b.breakpoint("1440") {
    width: 1180px;
  }
  @include b.breakpoint("1680") {
    width: 1380px;
  }
}

.notif {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  // border: 3px solid black;
  position: fixed;
  bottom: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  gap: 4px;

  // @include b.breakpoint("768") {
  width: 300px;
  height: fit-content;
  right: 1rem;
  top: 2rem;
  // }
}

.notification-alert {
  background-color: white;
  color: black;
  position: relative;
  // z-index: 2000;
  width: 80%;
  margin: 0 auto;
  padding: 0.7rem 1rem 0.7rem 1rem;
  border-radius: 10px 10px 0 10px;
  border-bottom: 0.3rem solid $accent;
  box-shadow: 0 0 12px 12px rgba(20, 20, 20, 0.103);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px;
    cursor: pointer;
  }
}

.nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  padding-bottom: 2.5rem;
  padding-top: 1rem;

  img {
    width: 300px;
    height: auto;
    object-fit: contain;
  }
  h3 {
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }
}
