//imports
@use "../../../styles/global/breakpoints" as b;
@import "../../../styles/global/variables";

.pricing-wraper {
  background-color: rgba(214, 214, 215, 0.2117647059);
  border-radius: 15px;
  padding-top: 2rem;

  .pricing-header {
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;

    @include b.breakpoint("1000") {
      gap: 1rem;
    }

    h2 {
      font-size: 23px;
      line-height: 1.1;
      font-weight: 600;

      @include b.breakpoint("768") {
        font-size: 2rem;
      }
    }
    h3 {
      font-size: 14px;
      line-height: 1.1;
      font-weight: 400;

      @include b.breakpoint("768") {
        font-size: 1.2rem;
      }
    }
  }

  .pricing-box {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    .pricing-box-heading {
      h3 {
        font-size: 27px;
        font-weight: 700;
        line-height: 1.2;
        text-align: start;

        &:last-child {
          text-align: end;

          @include b.breakpoint("1000") {
            text-align: start;
            margin-top: -2rem;
          }
        }

        @include b.breakpoint("768") {
          font-size: 42px;
        }
      }
      span {
        color: #636363;
        text-align: end;
      }

      .sep {
        display: block;
        height: 7px;
        width: 50px;
        border-radius: 50px;
        background-color: black;
        margin: 0.5rem 0;
        margin-bottom: -0.4rem;
        margin: 0 auto;

        @include b.breakpoint("1000") {
          margin-bottom: -1rem;
          margin: 1rem 0;
          height: 10px;
          width: 70px;
        }
      }
    }
  }

  .pricing-box-price {
    .pricing-box-price-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      font-weight: 600;

      p {
        font-weight: 500;
      }

      @include b.breakpoint("768") {
        font-size: 20px;
      }

      span {
        background-color: #262626;
        color: $white;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 13px;
      }
    }

    .pricing-body {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: 1fr;
      gap: 3rem;

      @include b.breakpoint("768") {
        margin-top: 3.5rem;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.7rem;
      }

      .pricing-elm {
        position: relative;
        background-color: $white;
        padding: 1.5rem;
        border-radius: 15px;
        box-shadow: 16px 16px 32px rgba(0, 0, 0, 0.1);

        display: flex;
        flex-direction: column;
        gap: 3rem;

        &.pro {
          background-color: $dash;
          color: $white;
        }

        .pricing-elm-head {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.5;
          }
          h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.5;

            span {
              font-size: 12px;
              color: #b8b8b8;
            }
          }
        }

        .pricing-elm-body {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          height: 100%;
          padding: 0 0 4.5rem 0;

          span {
            font-size: 15px;
            text-transform: uppercase;
          }
          .elm-notice {
            font-size: 13px;
            font-weight: 600;

            p {
              color: $accent;
              font-size: 15px;
              font-weight: 400;
            }
            .p-pro {
              color: #95a6e7;
            }
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 1.3rem;

            &.pro-elm {
              li {
                color: white;
                p {
                  font-weight: 500;
                  line-height: 1.8;
                  font-size: 13px;
                  color: #dbdbdb;

                  @include b.breakpoint("768") {
                    font-size: 14px;
                  }
                }
              }
            }

            li {
              display: grid;
              grid-template-columns: 20px 1fr;

              gap: 10px;
              align-items: flex-start;
              justify-content: flex-start;
              font-size: 20px;
              color: #95a6e7;

              p {
                color: #595959;
                font-weight: 500;
                line-height: 1.8;
                font-size: 13px;
                margin-top: -3px;
                text-transform: capitalize;

                @include b.breakpoint("768") {
                  font-size: 14px;
                }
              }
            }
          }

          button {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
          }

          button.pro-btn {
            background-color: white;
            color: black;
            border: transparent;
          }
        }

        .most-popular {
          position: absolute;
          background-color: $accent;
          color: #ededed;
          font-size: 11px;
          font-weight: 600;
          padding: 0.2rem 1.3rem;
          border-radius: 10px 10px 0 0;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 5px;

          top: -1.5rem;
          left: 50%;
          transform: translate(-50%, 0);

          span {
            font-weight: 400;
          }
        }
      }
    }
  }
}

.pricing-footer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  font-size: 25px;

  @include b.breakpoint("768") {
    font-size: 20px;
  }

  svg {
    color: $accent;
  }

  h3 {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.3;

    @include b.breakpoint("768") {
      line-height: 1;
    }
  }
}
