//imports
@use "../../styles/global/breakpoints" as b;
@import "../../styles/global/variables";

.login-f {
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-container {
  background-color: $white;
  width: 90%;
  box-shadow: 0 0 10px 5px rgba(16, 16, 29, 0.122);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2.5rem 1.8rem;
  border-radius: 15px;

  @include b.breakpoint("768") {
    width: 400px;
  }

  .heading {
    margin-bottom: 2rem;
    color: #252733;
    text-align: center;

    h1 {
      font-size: 30px;
    }
    img {
      width: 70% !important;
      height: auto;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    .inputs {
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    input {
      background-color: #fcfdfe;
      border-color: #adb4ba;
      color: #252733;
    }

    button {
      margin-top: 1rem;
    }
  }
}

.other-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 0.3rem;

  .quick {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    a {
      color: $accent;
    }
  }

  a {
    color: #252733;
    font-size: 14px;
    text-decoration: none;
  }
}
